import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
// import ArticlesComponent from "../components/articles";
import "../assets/css/h2.css";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";

import ReactMarkdown from "react-markdown";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    maxHeight: "min-content",
  },
  link: {
    textDecoration: "none",
  },
  headingLeft: {
    textAlign: "right",
    color: "#0000FF",
    textDecoration: "none",
  },
  headingRight: {
    textAlign: "left",
    color: "#0000FF",
    textDecoration: "none",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      whiteSpace: "normal",
    },
  },
  relative: {
    position: "relative",
  },
  arrowLine: {
    // height: "100%",
    // background: theme.palette.primary.main,
    // width: "0.3em",
    // right: 0,
    // position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
  },
  arrowSvg: {
    width: "50%",
    fill: "#0000FF",
  },
}));

const HideOnScroll = (props) => {
  const trigger = useScrollTrigger();
  return (
    <Slide in={!trigger}>
      <div>Hello</div>
    </Slide>
  );
};

const IndexPage = () => {
  const classes = useStyles();
  const data = useStaticQuery(query);

  const { hero, heading, introText } = data.strapiHomepage;
  console.log(heading, introText);
  // console.log(data.allStrapiSexualOrientation.edges[0].node.Headline);

  return (
    <Layout>
      <Typography className={classes.title} variant="h2" color="secondary">
        {hero.title}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={4} className={classes.relative}>
          <div className={classes.arrowLine}>
            <svg
              className={classes.arrowSvg}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 111.75 1028.21"
            >
              <defs></defs>
              <g id="Ebene_2" data-name="Ebene 2">
                <g id="Ebene_1-2" data-name="Ebene 1">
                  <polygon
                    class="cls-1"
                    points="101.73 958.81 62.88 1000.64 62.88 0 48.88 0 48.88 1000.64 10.02 958.81 0 968.15 55.88 1028.21 111.75 968.15 101.73 958.81"
                  />
                </g>
              </g>
            </svg>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h3">{heading}</Typography>
          <ReactMarkdown
            source={introText}
            escapeHtml={false}
            className={classes.pFixedHeight}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Link to="/gender" className={classes.link}>
            <Typography className={classes.headingLeft} variant="h2">
              Gender
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to="/sexuelle-orientierung" className={classes.link}>
            <Typography className={classes.headingRight} variant="h2">
              Sexuelle Orientierung
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      heading
      introText
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default IndexPage;
